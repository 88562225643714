// This component is at the top of every page

// Import react
import React from "react";

// Get firebase auth to log out
import { getAuth, signOut } from "firebase/auth";

// Get useNavigate to navigate to the login page
import { useNavigate } from "react-router-dom";

export default function Header (props) {

    const auth = getAuth();

    const handleSignOut = (event) => {signOut(auth).then(() => {
        // Sign-out successful.
        window.location.href = "https://ypo.bloccarbon.com/";
        }).catch((error) => {
        // An error happened.
        console.log(error)
        });
    };

    return (
        <header>
            <article className="left">
                <img className="logo" src="/white-logo-small.png" alt="BlocCarbon logo" />
            </article>
            <article className="center">
                <span>BlocCarbon x YPO Europe One</span>
            </article>
            <article className="right">
                <button className="logout" onClick={handleSignOut}>Logout</button>
            </article>
        </header>
    )
}