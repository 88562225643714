// This component allows input of catering emissions

// Import React
import React, { useState } from "react"

// Import react-router-dom stuff
import { useParams, useNavigate } from "react-router-dom";

// Import firestore stuff
import { doc, updateDoc } from "firebase/firestore";

// Import emission factors
import { emissionFactors } from "../app/emission_factors/emissionFactors";

// Import children components
import Header from "./Header"
import Footer from "./Footer"

// Import illustrations
import { ReactComponent as Meal } from '../images/meal.svg';
import { ReactComponent as Hydration } from '../images/hydration.svg';
import { ReactComponent as Coffee } from '../images/coffee.svg';
import { ReactComponent as Alcohol } from '../images/beer.svg';


export default function CateringEmissions ({user, organisation, events, flightEmissions, carEmissions, publicTransportEmissions, hotelStayEmissions, db}) {
    
    const navigate = useNavigate();

    const { eventId } = useParams();

    const eventRef = doc(db, "events", eventId);

    const event = events.find(event => event.id === eventId);

    // For each input we need a state
    // We seperate the states into sections based on their cards and each section has their own logic to update their docs
    // Meals
    const [vegMeals, setVegMeals] = useState(event.emissions.vegMeals);
    const [nonVegMeals, setNonVegMeals] = useState(event.emissions.nonVegMeals);
    const [snacks, setSnacks] = useState(event.emissions.snacks);
    
    const totalMeals = (vegMeals * emissionFactors.vegMeals) + (nonVegMeals * emissionFactors.nonVegMeals) + (snacks * emissionFactors.snacks);
    
    const handleSaveMeals = async (event) => {
        event.preventDefault();
        const mealsButton = document.getElementById('meals-button');
        mealsButton.textContent = 'Saving';
        await updateDoc(eventRef, {
            'emissions.vegMeals': vegMeals,
            'emissions.nonVegMeals': nonVegMeals,
            'emissions.snacks': snacks
        });
        mealsButton.textContent = 'Saved';
    };

    // hydrationEmissions
    const [water, setWater] = useState(event.emissions.water);
    const [softDrinks, setSoftDrinks] = useState(event.emissions.softDrinks);
    
    const totalHydration = (water * emissionFactors.water) + (softDrinks * emissionFactors.softDrinks)
    
    const handleSaveHydration = async (event) => {
        event.preventDefault();
        const hydrationButton = document.getElementById('hydration-button');
        hydrationButton.textContent = 'Saving';
        await updateDoc(eventRef, {
            'emissions.water': water,
            'emissions.softDrinks': softDrinks
        });
        hydrationButton.textContent = 'Saved';
    };

    // coffeeTeaEmissions
    const [coffee, setCoffee] = useState(event.emissions.coffee);
    const [tea, setTea] = useState(event.emissions.tea);
    
    const totalCoffeeTea = (coffee * emissionFactors.coffee) + (tea * emissionFactors.tea)
    
    const handleSaveCoffeeTea = async (event) => {
        event.preventDefault();
        const coffeeTeaButton = document.getElementById('coffee-tea-button');
        coffeeTeaButton.textContent = 'Saving';
        await updateDoc(eventRef, {
            'emissions.coffee': coffee,
            'emissions.tea': tea
        });
        coffeeTeaButton.textContent = 'Saved';
    };

    // alcohol
    const [wine, setWine] = useState(event.emissions.wine);
    const [beer, setBeer] = useState(event.emissions.beer);
    const [spirits, setSpirits] = useState(event.emissions.spirits);
    
    const totalAlcohol = (wine * emissionFactors.wine) + (beer * emissionFactors.beer) + (spirits * emissionFactors.spirits);
    
    const handleSaveAlcohol = async (event) => {
        event.preventDefault();
        const alcoholButton = document.getElementById('alcohol-button');
        alcoholButton.textContent = 'Saving';
        await updateDoc(eventRef, {
            'emissions.wine': wine,
            'emissions.beer': beer,
            'emissions.spirits': spirits
        });
        alcoholButton.textContent = 'Saved';
    };
    
    return (
        <section className="page">
            
            <Header />

            <section className="page-content">
                
                <button className="back-button white" onClick={e => navigate(-1)}>&larr; Back</button>

                <h2 className="white">Catering Emissions</h2>

                <section className="card-list">

                    <form className="input-card">
                        <Meal />
                        <h4 className="white">Meals</h4>
                        <h5 className="white">Total: {totalMeals.toFixed(2)} TCO2</h5>
                        <article className="input-article">
                            <label className="white" for="veg">Number of vegetarian meals</label>
                            <input type={"number"} name="veg" id="veg" placeholder="Number of veg meals" value={vegMeals} onChange={event => {setVegMeals(event.target.value); document.getElementById('meals-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="nonVeg">Number of non-veg meals</label>
                            <input type={"number"} name="nonVeg" id="nonVeg" placeholder="Number of non-veg meals" value={nonVegMeals} onChange={event => {setNonVegMeals(event.target.value); document.getElementById('meals-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="snacks">Number of snacks</label>
                            <input type={"number"} name="snacks" id="snacks" placeholder="Number of snacks" value={snacks} onChange={event => {setSnacks(event.target.value); document.getElementById('meals-button').textContent = 'Save';}}/>
                        </article>
                        <button type="submit" id="meals-button" className="orange-background white" onClick={handleSaveMeals}>Saved</button>
                    </form>

                    <form className="input-card">
                        <Hydration />
                        <h4 className="white">Hydration</h4>
                        <h5 className="white">Total: {totalHydration.toFixed(2)} TCO2</h5>
                        <article className="input-article">
                            <label className="white" for="water">Litres of water</label>
                            <input type={"number"} name="water" id="water" placeholder="Litres of water" value={water} onChange={event => {setWater(event.target.value); document.getElementById('hydration-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="softDrinks">Litres of soft drinks</label>
                            <input type={"number"} name="softDrinks" id="softDrinks" placeholder="Litres of soft drinks" value={softDrinks} onChange={event => {setSoftDrinks(event.target.value); document.getElementById('hydration-button').textContent = 'Save';}}/>
                        </article>
                        <button type="submit" id="hydration-button" className="orange-background white" onClick={handleSaveHydration}>Saved</button>
                    </form>

                    <form className="input-card">
                        <Coffee />
                        <h4 className="white">Coffee / Tea</h4>
                        <h5 className="white">Total: {totalCoffeeTea.toFixed(2)} TCO2</h5>
                        <article className="input-article">
                            <label className="white" for="coffee">Cups of coffee</label>
                            <input type={"number"} name="coffee" id="coffee" placeholder="Cups of coffee" value={coffee} onChange={event => {setCoffee(event.target.value); document.getElementById('coffee-tea-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="tea">Cups of tea</label>
                            <input type={"number"} name="tea" id="tea" placeholder="Cups of tea" value={tea} onChange={event => {setTea(event.target.value); document.getElementById('coffee-tea-button').textContent = 'Save';}}/>
                        </article>
                        <button type="submit" id="coffee-tea-button" className="orange-background white" onClick={handleSaveCoffeeTea}>Saved</button>
                    </form>

                    <form className="input-card">
                        <Alcohol />
                        <h4 className="white">Alcohol</h4>
                        <h5 className="white">Total: {totalAlcohol.toFixed(2)} TCO2</h5>
                        <article className="input-article">
                            <label className="white" for="wine">Litres of wine</label>
                            <input type={"number"} name="wine" id="wine" placeholder="Litres of wine" value={wine} onChange={event => {setWine(event.target.value); document.getElementById('alcohol-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="beer">Litres of beer</label>
                            <input type={"number"} name="beer" id="beer" placeholder="Litres of beer" value={beer} onChange={event => {setBeer(event.target.value); document.getElementById('alcohol-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="spirits">Litres of spirits</label>
                            <input type={"number"} name="spirits" id="spirits" placeholder="Litres of spirits" value={spirits} onChange={event => {setSpirits(event.target.value); document.getElementById('alcohol-button').textContent = 'Save';}}/>
                        </article>
                        <button type="submit" id="alcohol-button" className="orange-background white" onClick={handleSaveAlcohol}>Saved</button>
                    </form>

                </section>
    
            </section>

            <Footer />

        </section>
    )
};