// This component manages the login state

// Import react
import React, { useState, useEffect } from "react";

// Import Firebase auth stuff
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

import Footer from "./Footer";

export default function Login ({setLogin}) {

  // If there is already a stored email address for auth, display waiting message
  const [awaitingAuth, setAwaitingAuth] = useState(window.localStorage.getItem('emailForSignIn') ? true : false)

  const auth = getAuth();

  // Provide functionality to login
  // Start by configuring the actionCodeSettings
  const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://ypo.bloccarbon.com',
  // This must be true.
  handleCodeInApp: true,
  };

  const [email, setEmail] = useState('');

  const [message, setMessage] = useState('Enter your email address');

  // When someone tries to login, send them the login link using the parameters from the action code setting and store their email locally
  const handleLoginAttempt = (event) => {
      event.preventDefault();
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        setMessage('Check your email address for a login link');
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        setAwaitingAuth(true);
        // ...
      })
      .catch((error) => {
        console.log(error.code)
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }

  // When this component loads, if there is a valid email sign-in link matching an email stored locally, it will sign them in
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          setLogin(true);
          setAwaitingAuth(false);
          window.location.href = "https://ypo.bloccarbon.com/";
        })
        .catch((error) => {
          console.log(error.code)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, [])

  return (
      <section className="page">

          <header className="login-header">
              <article className="left">
                  <img className="logo" src="/white-logo-small.png" alt="BlocCarbon logo" />
                  <span className="white">BlocCarbon</span>
              </article>
          </header>

          <section className="login-page-content green-background">
              <img className="big-logo" src="white-logo-big.png" alt="BlocCarbon logo" />

              {awaitingAuth ? 
              <article className="login"><p className="white">Please check your email address for a login link</p></article> 
              
              :
              
              <form className="login">
                  <h3 className="white">Login</h3>
                  <article>
                      <label className="white" htmlFor="email">Email</label>
                      <input type="email" placeholder="Email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
                  </article>
                  {/* <article>
                      <label className="white" htmlFor="password">Password</label>
                      <input type="password" placeholder="Password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
                  </article> */}
                  <button className="orange-background white" type="submit" onClick={handleLoginAttempt}>Login</button>
                  <p className="white">{message}</p>
              </form>}
          </section>
          <Footer />

      </section>
  )
}