// This is a constant top level component that manages all of the state of the application once the user is logged in

// Import react
import React, { useEffect, useState } from "react";

// Import react-router-dom stuff
import { BrowserRouter, Routes, Route, redirect } from 'react-router-dom';

// Import firestore stuff
import { doc, onSnapshot, collection, query, where, getDoc } from "firebase/firestore";

// Import children components
import Dashboard from '../components/Dashboard';
import EventSpacesEmissions from '../components/EventSpacesEmissions';
import CateringEmissions from '../components/CateringEmissions';
import MaterialsEmissions from '../components/MaterialsEmissions';
import AttendeeCalculator from '../components/AttendeeCalculator';
import AdminDashboard from '../components/AdminDashboard';
import Header from "./Header";
import Footer from "./Footer";


export default function Constant ({userId, db}) {

    // Monitor the details of the current user
    const [currentUser, setCurrentUser] = useState();
    const getUser = async () => {
        const userDocRef = doc(db, "users", userId);
        const docSnap = await getDoc(userDocRef);
        setCurrentUser({...docSnap.data(), id: docSnap.id})
    }
    useEffect(() => {
        getUser();
    }, [])

    // Monitor the users organisation
    const [organisation, setOrganisation] = useState();
    useEffect(() => {
        let unsub;
        if (currentUser) {
            unsub = onSnapshot(doc(db, "organisations", currentUser.organisations[0]), (doc) => {
                setOrganisation({...doc.data(), id: doc.id});
            });
        }
        return unsub
    }, [currentUser, db])
    
    // Monitor the user's events
    const [events, setEvents] = useState();
    useEffect(() => {
        let unsub;
        if (currentUser && organisation) {
            const eventsQuery = query(collection(db, "events"), where("organisation", "==", organisation.id));
            unsub = onSnapshot(eventsQuery, (querySnapshot) => {
            const eventsResult = [];
            querySnapshot.forEach((doc) => {
                eventsResult.push({...doc.data(), id: doc.id});
            });
            setEvents(eventsResult);
            });
        }
        return unsub;
    }, [currentUser, organisation, db])

    // Monitor the user's air travel emissions
    const [flightEmissions, setFlightEmissions] = useState();
    useEffect(() => {
        let unsub;
        if (currentUser) {
            const q = query(collection(db, "flightEmissions"), where("user", "==", userId));
            unsub = onSnapshot(q, (querySnapshot) => {
            const result = [];
            querySnapshot.forEach((doc) => {
                result.push({...doc.data(), id: doc.id});
            });
            if (result.length > 0) {
                setFlightEmissions(result);
            }
            });
        }
        return unsub;
    }, [currentUser, userId, db])


    // Monitor the user's car travel emissions
    const [carEmissions, setCarEmissions] = useState();
    useEffect(() => {
        let unsub;
        if (currentUser) {
            const q = query(collection(db, "carEmissions"), where("user", "==", userId));
            unsub = onSnapshot(q, (querySnapshot) => {
            const result = [];
            querySnapshot.forEach((doc) => {
                result.push({...doc.data(), id: doc.id});
            });
            if (result.length > 0) {
                setCarEmissions(result);
            }
            });
        }
        return unsub;
    }, [currentUser, userId, db])

    // Monitor the user's public transport emissions
    const [publicTransportEmissions, setPublicTransportEmissions] = useState();
    useEffect(() => {
        let unsub;
        if (currentUser) {
            const q = query(collection(db, "publicTransportEmissions"), where("user", "==", userId));
            unsub = onSnapshot(q, (querySnapshot) => {
            const result = [];
            querySnapshot.forEach((doc) => {
                result.push({...doc.data(), id: doc.id});
            });
            if (result.length > 0) {
                setPublicTransportEmissions(result);
            }
            });
        }
        return unsub;
    }, [currentUser, userId, db])

    // Monitor the user's hotel stay emissions
    const [hotelStayEmissions, setHotelStayEmissions] = useState();
    useEffect(() => {
        let unsub;
        if (currentUser) {
            const q = query(collection(db, "hotelStayEmissions"), where("user", "==", userId));
            unsub = onSnapshot(q, (querySnapshot) => {
            const result = [];
            querySnapshot.forEach((doc) => {
                result.push({...doc.data(), id: doc.id});
            });
            if (result.length > 0) {
                setHotelStayEmissions(result);
            }
            });
        }
        return unsub;
    }, [currentUser, userId, db])


    return (
        currentUser && organisation && events ? 
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Dashboard user={currentUser} organisation={organisation} events={events} flightEmissions={flightEmissions} carEmissions={carEmissions} publicTransportEmissions={publicTransportEmissions} hotelStayEmissions={hotelStayEmissions} db={db} />} />
            <Route path=':eventId/personal-emissions' element={<AttendeeCalculator user={currentUser} organisation={organisation} events={events} flightEmissions={flightEmissions} carEmissions={carEmissions} publicTransportEmissions={publicTransportEmissions} hotelStayEmissions={hotelStayEmissions} db={db} />}/>
            <Route path=':eventId/admin' element={<AdminDashboard user={currentUser} organisation={organisation} events={events} flightEmissions={flightEmissions} carEmissions={carEmissions} publicTransportEmissions={publicTransportEmissions} hotelStayEmissions={hotelStayEmissions} db={db} />} loader={()=> {if (organisation.admins.indexOf(currentUser.id) === -1) {redirect(':/eventId/personal-emissions')}}} />
            <Route path=':eventId/admin/event-spaces' element={<EventSpacesEmissions user={currentUser} organisation={organisation} events={events} flightEmissions={flightEmissions} carEmissions={carEmissions} publicTransportEmissions={publicTransportEmissions} hotelStayEmissions={hotelStayEmissions} db={db} />} loader={()=> {if (organisation.admins.indexOf(currentUser.id) === -1) {redirect(':/eventId/personal-emissions')}}} />
            <Route path=':eventId/admin/catering' element={<CateringEmissions user={currentUser} organisation={organisation} events={events} flightEmissions={flightEmissions} carEmissions={carEmissions} publicTransportEmissions={publicTransportEmissions} hotelStayEmissions={hotelStayEmissions} db={db} />} loader={()=> {if (organisation.admins.indexOf(currentUser.id) === -1) {redirect(':/eventId/personal-emissions')}}} />
            <Route path=':eventId/admin/materials' element={<MaterialsEmissions user={currentUser} organisation={organisation} events={events} flightEmissions={flightEmissions} carEmissions={carEmissions} publicTransportEmissions={publicTransportEmissions} hotelStayEmissions={hotelStayEmissions} db={db} />} loader={()=> {if (organisation.admins.indexOf(currentUser.id) === -1) {redirect(':/eventId/personal-emissions')}}} />
        </Routes>
        </BrowserRouter> :
        <section className="page">
            <Header />

            <section className="page-content loading-page-content">
                <p className="white">Loading</p>
            </section>

            <Footer />
        </section>
    )
}