export const emissionFactors = {
    eventSpaceEmissions: {
        heating: 0.0001,
        aircon: 0.0001
    },
    vegMeals: 0.002,
    nonVegMeals: 0.003,
    snacks: 0.001,
    water: 0.001,
    softDrinks: 0.001,
    coffee: 0.001,
    tea: 0.001,
    wine: 0.001,
    beer: 0.001,
    spirits: 0.003,
    marketingPaper: 0.001,
    cardboard: 0.0008,
    billboards: 0.1142,
    plastic: 0.003,
    giftPaper: 0.001,
    wood: 0.0004,
    tShirts: 0.0001,
    shirts: 0.0002,
    jackets: 0.0006,
    jumpers: 0.0003,
    trousers: 0.0003,
    shoes: 0.0009,
    flightEmissions: 0.25,
    carEmissions: 0.000174,
    publicTransportEmissions: 0.075,
    hotelStayEmissions: {
        '3 star': 0.020,
        '4 star': 0.034,
        '5 star': 0.049
    }
}