// This component creates a summary card for an event

// Import React
import React, { useEffect, useState } from "react";

// Import react-router-dom stuff
import { Link } from "react-router-dom";

// Import firebase stuff 
import { collection, query, where, getDocs } from "firebase/firestore";

// Import emission factors
import { emissionFactors } from "../app/emission_factors/emissionFactors";

export default function EventSummaryCard ({user, isAdmin, event, organisation, flightEmissions, carEmissions, publicTransportEmissions, hotelStayEmissions, db}) {

    const eventId = event.id;

    // Calculate the event emissions
    // Event space emissions
    let initialEventSpacesEmissions = 0
    if (isAdmin) {
        let eventSpacesEmissions = [...event.emissions.eventSpacesEmissions]
        eventSpacesEmissions.forEach(space => {
            if (space.heating) {
                initialEventSpacesEmissions = initialEventSpacesEmissions + (space.hours * space.size * emissionFactors.eventSpaceEmissions.heating);
            }
            if (space.aircon) {
                initialEventSpacesEmissions = initialEventSpacesEmissions + (space.hours * space.size * emissionFactors.eventSpaceEmissions.aircon);
            }
        });

    }
    const [eventSpacesEmissions, setEventSpacesEmissions] = useState(initialEventSpacesEmissions);

    // Catering emissions
    let initialCateringEmissions = 0
    if (isAdmin) {
        initialCateringEmissions = initialCateringEmissions + (event.emissions.vegMeals * emissionFactors.vegMeals);
        initialCateringEmissions = initialCateringEmissions + (event.emissions.nonVegMeals * emissionFactors.nonVegMeals);
        initialCateringEmissions = initialCateringEmissions + (event.emissions.snacks * emissionFactors.snacks);
        initialCateringEmissions = initialCateringEmissions + (event.emissions.water * emissionFactors.water);
        initialCateringEmissions = initialCateringEmissions + (event.emissions.coffee * emissionFactors.coffee);
        initialCateringEmissions = initialCateringEmissions + (event.emissions.tea * emissionFactors.tea);
        initialCateringEmissions = initialCateringEmissions + (event.emissions.wine * emissionFactors.wine);
        initialCateringEmissions = initialCateringEmissions + (event.emissions.beer * emissionFactors.beer);
        initialCateringEmissions = initialCateringEmissions + (event.emissions.spirits * emissionFactors.spirits);
    }
    const [cateringEmissions, setCateringEmissions] = useState(initialCateringEmissions);

    // Promo materials emissions
    let initialPromoMaterialEmissions = 0;
    if (isAdmin) {
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.marketingPaper * emissionFactors.marketingPaper);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.cardboard * emissionFactors.cardboard);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.billboards * emissionFactors.billboards);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.plastic * emissionFactors.plastic);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.giftPaper * emissionFactors.giftPaper);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.wood * emissionFactors.wood);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.tShirts * emissionFactors.tShirts);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.shirts * emissionFactors.shirts);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.jackets * emissionFactors.jackets);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.jumpers * emissionFactors.jumpers);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.trousers * emissionFactors.trousers);
        initialPromoMaterialEmissions = initialPromoMaterialEmissions + (event.emissions.shoes * emissionFactors.shoes);
    }
    const [promoMaterialsEmissions, setPromoMaterialsEmissions] = useState(initialPromoMaterialEmissions);

    const totalEventEmissions = eventSpacesEmissions + cateringEmissions + promoMaterialsEmissions;

    // Calculate the attendee emissions
    const calculateTotalAttendeeEmissions = async (eventId) => {
        if (isAdmin) {
            // Aggregated flight emissions
            let totalFlights = 0;
            const flightsQuery = query(collection(db, "flightEmissions"), where("event", "==", eventId));
            const flightsSnapshot = await getDocs(flightsQuery);
            flightsSnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            totalFlights = totalFlights + (doc.data().duration * emissionFactors.flightEmissions);
            });
            // Aggregated car emissions
            let totalCars = 0;
            const carsQuery = query(collection(db, "carEmissions"), where("event", "==", eventId));
            const carsSnapshot = await getDocs(carsQuery);
            carsSnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            totalCars = totalCars + (doc.data().distance * emissionFactors.carEmissions);
            });
            // Aggregated public transport emissions
            let totalPublicTransport = 0;
            const publicTransportQuery = query(collection(db, "publicTransportEmissions"), where("event", "==", eventId));
            const publicTransportSnapshot = await getDocs(publicTransportQuery);
            publicTransportSnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            totalPublicTransport = totalPublicTransport + (doc.data().duration * emissionFactors.publicTransportEmissions);
            });
            // Aggregated hotel stay emissions
            let totalHotelStay = 0;
            const hotelStayQuery = query(collection(db, "hotelStayEmissions"), where("event", "==", eventId));
            const hotelStaySnapshot = await getDocs(hotelStayQuery);
            hotelStaySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            totalHotelStay = totalHotelStay + (doc.data().duration * emissionFactors.hotelStayEmissions[doc.data().stars]);
            });
            return totalFlights + totalCars + totalPublicTransport + totalHotelStay;
        }
        else {
            return 0
        }
   }

   const [totalAttendeeEmissions, setTotalAttendeeEmissions] = useState(0);
   useEffect(() => {
    const getAttendeeEmissions = async () => {
        const attendeeEmissions = await calculateTotalAttendeeEmissions(event.id);
        setTotalAttendeeEmissions(attendeeEmissions)
    }
    getAttendeeEmissions();
   }, []);

   const totalAdminDisplayedEmissions = totalAttendeeEmissions + totalEventEmissions;

   // Calculate the personal emissions
   const personalFlightEmissions = (flightEmissions ? (flightEmissions.find(flightEmission => flightEmission.event === event.id) ? flightEmissions.find(flightEmission => flightEmission.event === event.id).duration * emissionFactors.flightEmissions : 0) : 0);

   const personalCarEmissions = (carEmissions ? (carEmissions.find(carEmission => carEmission.event === event.id) ? carEmissions.find(carEmission => carEmission.event === eventId).distance * emissionFactors.carEmissions : 0) : 0);

   const personalPublicTransportEmissions = (publicTransportEmissions ? (publicTransportEmissions.find(publicTransportEmission => publicTransportEmission.event === eventId) ? publicTransportEmissions.find(publicTransportEmission => publicTransportEmission.event === eventId).duration * emissionFactors.publicTransportEmissions : 0) : 0);

   const hotelStay = hotelStayEmissions ? (hotelStayEmissions.find(hotelStayEmission => hotelStayEmission.event === eventId) ? hotelStayEmissions.find(hotelStayEmission => hotelStayEmission.event === eventId) : null) : null;
   const personalHotelEmissions = hotelStay ?  (hotelStay.duration * emissionFactors.hotelStayEmissions[hotelStay.stars]) : 0;

   const totalPersonalEmissions = personalFlightEmissions + personalCarEmissions + personalPublicTransportEmissions + personalHotelEmissions;

   const getLink = (eventId) => {
    if (isAdmin) {
        return `/${eventId}/admin`
    }
    if (!isAdmin) {
        return `/${eventId}/personal-emissions`
    }
}

    return (
        <Link to={`${getLink(event.id)}`}>
            <article className="event-card">
                <div className="card-image" style={{backgroundImage: `url(${event.image})`}}></div>
                <h4 className="black">{event.name.toString()}</h4>
                <article className="emissions-total">
                    <p className="black">Emissions:</p>
                    <p className="orange" style={{fontWeight: 'bold'}}>{isAdmin ? (totalAdminDisplayedEmissions ? totalAdminDisplayedEmissions.toFixed(2) + ' TCO2' : 'Not recorded') : (totalPersonalEmissions ? totalPersonalEmissions.toFixed(2) + ' TCO2' : 'Not recorded')}</p>
                </article>
                <button className="orange-background white">View details</button>
            </article>
        </Link>
    )
}