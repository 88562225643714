// Import react
import React, { useState } from 'react';

// Import styles
import './App.css';

// Import Firebase stuff
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, } from "firebase/firestore";


// Import children components
import Login from '../components/Login.js';
import Constant from '../components/Constant';



export default function App() {

  // Set up firebase app
  const firebaseConfig = {
    apiKey: "AIzaSyAc0UfsdkBn0lFa8qkHaIFzChTqgVOHlzw",
    authDomain: "bloccarbonypo.firebaseapp.com",
    projectId: "bloccarbonypo",
    storageBucket: "bloccarbonypo.appspot.com",
    messagingSenderId: "207280039959",
    appId: "1:207280039959:web:19c496c0a2c76fa2575452",
    measurementId: "G-HBDJM95F66"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth();
  const db = getFirestore(app);

  // Manage the login state of the user
  const [login, setLogin] = useState(false);

  const [userId, setUserId] = useState('');

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      if (!login) {
        setLogin(true);
      }
      setUserId(user.uid);
      // 
      // ...
    } else {
      // User is signed out
      // ...
      setLogin(false);
      setUserId('')   
    }
  });

  return (
    login && userId ? <Constant userId={userId} db={db} /> : <Login setLogin={setLogin} />
  );
};