// This component allows attendees to calculate their emissions

// Import React
import React, { useState } from "react"

// Import react-router-dom stuff
import { useParams, useNavigate } from "react-router-dom";

// Import firestore stuff
import { doc, setDoc, updateDoc, collection, addDoc } from "firebase/firestore";

// Import emission factors
import { emissionFactors } from "../app/emission_factors/emissionFactors";

// Import children components
import Header from "./Header";
import Footer from "./Footer";

// Import illustrations
import { ReactComponent as Airplane } from '../images/airplane.svg';
import { ReactComponent as Car } from '../images/car.svg';
import { ReactComponent as BusStop } from '../images/bus-stop.svg';
import { ReactComponent as Hotel } from '../images/hotel.svg';


export default function AttendeeCalculator ({user, organisation, events, flightEmissions, carEmissions, publicTransportEmissions, hotelStayEmissions, db}) {
   
    const navigate = useNavigate();

    const { eventId } = useParams();

    // Flight emissions
    let initialFlightEmission = flightEmissions ? flightEmissions.find(flightEmission => flightEmission.event === eventId) : null;
    
    const [flightDuration, setFlightDuration] = useState(initialFlightEmission ? initialFlightEmission.duration : 0);

    const totalFlightEmission = flightDuration * emissionFactors.flightEmissions;

    // If there is no existing emission, then onSave must create a new one, otherwise it can edit an existing one
    const handleFlightSave = async (event) => {
        event.preventDefault();
        document.getElementById('flight-button').textContent = 'Saving';
        if (!initialFlightEmission)  {
            await addDoc(collection(db, "flightEmissions"), {
                user: user.id,
                event: eventId,
                duration: flightDuration,
            });
        }
        else if (initialFlightEmission) {
            const flightDocRef = await doc(db, "flightEmissions", initialFlightEmission.id)
            await updateDoc(flightDocRef, {
                duration: flightDuration
            });
        }
        document.getElementById('flight-button').textContent = 'Saved';
    };

    // Car emissions
    let initialCarEmission = carEmissions ? carEmissions.find(carEmission => carEmission.event === eventId) : null;
    
    const [carDistance, setCarDistance] = useState(initialCarEmission ? initialCarEmission.distance : 0);

    const totalCarEmission = carDistance * emissionFactors.carEmissions;

    // If there is no existing emission, then onSave must create a new one, otherwise it can edit an existing one
    const handleCarSave = async (event) => {
        event.preventDefault();
        document.getElementById('car-button').textContent = 'Saving';
        if (!initialCarEmission) {
            await addDoc(collection(db, "carEmissions"), {
                user: user.id,
                event: eventId,
                distance: carDistance,
            });
        }
        else if (initialCarEmission) {
            const Ref = doc(db, "carEmissions", initialCarEmission.id);
            await updateDoc(Ref, {
                distance: carDistance
            });
        }
        document.getElementById('car-button').textContent = 'Saved';
    };

    
    // Public transport emissions
    let initialPublicTransportEmission = publicTransportEmissions ? publicTransportEmissions.find(emission => emission.event === eventId) : null;
    
    const [publicTransportDuration, setPublicTransportDuration] = useState(initialPublicTransportEmission ? initialPublicTransportEmission.duration : 0);

    const totalpublicTransportEmission = publicTransportDuration * emissionFactors.publicTransportEmissions;

    // If there is no existing emission, then onSave must create a new one, otherwise it can edit an existing one
    const handlePublicTransportSave = async (event) => {
        event.preventDefault();
        document.getElementById('public-transport-button').textContent = 'Saving';
        if (!initialPublicTransportEmission) {
            await addDoc(collection(db, "publicTransportEmissions"), {
                user: user.id,
                event: eventId,
                duration: publicTransportDuration,
            });
        }
        else if (initialPublicTransportEmission) {
            const Ref = doc(db, "publicTransportEmissions", initialPublicTransportEmission.id);
            await updateDoc(Ref, {
                duration: publicTransportDuration
            });
        }
        document.getElementById('public-transport-button').textContent = 'Saved';
    };
    
    // Hotel stay emissions
    let initialHotelStayEmission = hotelStayEmissions ? hotelStayEmissions.find(emission => emission.event === eventId) : null;
    
    const [hotelStayDuration, setHotelStayDuration] = useState(initialHotelStayEmission ? initialHotelStayEmission.duration : 0);
    const [hotelStayStars, setHotelStayStars] = useState(initialHotelStayEmission ? initialHotelStayEmission.stars : '3 star');

    const totalHotelStayEmission = hotelStayDuration * emissionFactors.hotelStayEmissions[hotelStayStars];

    // If there is no existing emission, then onSave must create a new one, otherwise it can edit an existing one
    const handleHotelStaySave = async (event) => {
        event.preventDefault();
        document.getElementById('hotel-button').textContent = 'Saving'
        if (!initialHotelStayEmission) {
            await addDoc(collection(db, "hotelStayEmissions"), {
                user: user.id,
                event: eventId,
                duration: hotelStayDuration,
                stars: hotelStayStars
            });
        }
        else if (initialHotelStayEmission) {
            const Ref = doc(db, "hotelStayEmissions", initialHotelStayEmission.id);
            await updateDoc(Ref, {
                duration: hotelStayDuration,
                stars: hotelStayStars
            });
        }
        document.getElementById('hotel-button').textContent = 'Saved'
    };

    
    return (
        <section className="page">

            <Header />

            <section className="page-content">

            <button className="back-button white" onClick={e => navigate(-1)}>&larr; Back</button>

            <h2 className="white">My Emissions</h2>

            <section className="card-list">
               
                <form className="input-card">
                    <Airplane />
                    <h4 className="white">Air Travel</h4>
                    <h5 className="white">Total: {totalFlightEmission} TCO2</h5>
                    <article className="input-article">
                        <label className="white" for="flightDuration">Hours spent flying</label>
                        <input type={"number"} name="flightDuration" id="flightDuration" placeholder="Hours spent flying" value={flightDuration} onChange={event => {setFlightDuration(event.target.value); document.getElementById('flight-button').textContent = 'Save';}}/>
                    </article>
                    <button type="submit" id="flight-button" className="orange-background white" onClick={handleFlightSave}>Saved</button>
                </form>

                <form className="input-card">
                    <Car />
                    <h4 className="white">Car Travel</h4>
                    <h5 className="white">Total: {totalCarEmission.toFixed(2)} TCO2</h5>
                    <article className="input-article">
                        <label className="white" for="carDistance">Km travelled</label>
                        <input type={"number"} name="carDistance" id="carDistance" placeholder="Km travelled" value={carDistance} onChange={event => {setCarDistance(event.target.value); document.getElementById('car-button').textContent = 'Save';}}/>
                    </article>
                    <button type="submit" id="car-button" className="orange-background white" onClick={handleCarSave}>Saved</button>
                </form>

                <form className="input-card">
                    <BusStop />
                    <h4 className="white">Public Transport</h4>
                    <h5 className="white">Total: {totalpublicTransportEmission.toFixed(2)} TCO2</h5>
                    <article className="input-article">
                        <label className="white" for="publicTransportDuration">Hrs spent on public transport</label>
                        <input type={"number"} name="publicTransportDuration" id="publicTransportDuration" placeholder="Hrs spent on public transport" value={publicTransportDuration} onChange={event => {setPublicTransportDuration(event.target.value); document.getElementById('public-transport-button').textContent = 'Save';}}/>
                    </article>
                    <button type="submit" id="public-transport-button" className="orange-background white" onClick={handlePublicTransportSave}>Saved</button>
                </form>

                <form className="input-card">
                    <Hotel />
                    <h4 className="white">Hotel Stay</h4>
                    <h5 className="white">Total: {totalHotelStayEmission.toFixed(2)} TCO2</h5>
                    <article className="dropdown-container-article">
                        <article className="dropdown-article">
                            <label className="white" for="nights">Duration</label>
                            <select name="nights" id="nights" value={hotelStayDuration} onChange={(event) => {setHotelStayDuration(event.target.value); document.getElementById('hotel-button').textContent = 'Save';}}>
                                <option value={0}>Duration</option>
                                <option value={1}>1 night</option>
                                <option value={2}>2 nights</option>
                                <option value={3}>3 nights</option>
                                <option value={4}>4 nights</option>
                                <option value={5}>5 nights</option>
                                <option value={6}>6 nights</option>
                                <option value={7}>7 nights</option>
                                <option value={8}>8 nights</option>
                                <option value={9}>9 nights</option>
                                <option value={10}>10 nights</option>
                            </select>   
                        </article>
                        <article className="dropdown-article">
                            <label className="white" for="stars">Stars</label>
                            <select name="stars" id="stars" value={hotelStayStars} onChange={(event) => {setHotelStayStars(event.target.value); document.getElementById('hotel-button').textContent = 'Save';}}>
                                <option value={'3 star'}>3 star</option>
                                <option value={'4 star'}>4 star</option>
                                <option value={'5 star'}>5 star</option>
                            </select>   
                        </article>
                    </article>
                    <button type="submit" id="hotel-button" className="orange-background white" onClick={handleHotelStaySave}>Saved</button>
                </form>

            </section>

            </section>

            <Footer />

        </section>
    )
}