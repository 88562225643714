// This is a dashboard for a particular event which only Admins can see

// Import react
import React, { useEffect, useState } from "react";

// Import react-router-dom stuff
import { Link, useParams, useNavigate } from "react-router-dom";

// Import firebase stuff
import { collection, query, where, getDocs } from "firebase/firestore";

// Import emission factors
import { emissionFactors } from "../app/emission_factors/emissionFactors";

// Import illustrations
import { ReactComponent as Location } from '../images/location.svg';
import { ReactComponent as Catering } from '../images/catering.svg';
import { ReactComponent as Materials } from '../images/materials.svg';
import { ReactComponent as Globe } from '../images/globe.svg';

// Import children components
import Header from "./Header";
import Footer from "./Footer";


export default function AdminDashboard ({user, organisation, events, flightEmissions, carEmissions, publicTransportEmissions, hotelStayEmissions, db}) {
    
    const navigate = useNavigate();

    const { eventId } = useParams();
    const event = events.find(event => event.id === eventId);


    // Calculate the event emissions
    let eventSpacesEmissions = 0;
    event.emissions.eventSpacesEmissions.forEach(space => {
        if (space.heating) {
            eventSpacesEmissions = eventSpacesEmissions + (space.hours * space.size * emissionFactors.eventSpaceEmissions.heating)
        }
        if (space.aircon) {
            eventSpacesEmissions = eventSpacesEmissions + (space.hours * space.size * emissionFactors.eventSpaceEmissions.aircon)
        }
    })

    let cateringEmissions = 0
    cateringEmissions = cateringEmissions + (event.emissions.vegMeals * emissionFactors.vegMeals);
    cateringEmissions = cateringEmissions + (event.emissions.nonVegMeals * emissionFactors.nonVegMeals);
    cateringEmissions = cateringEmissions + (event.emissions.snacks * emissionFactors.snacks);
    cateringEmissions = cateringEmissions + (event.emissions.water * emissionFactors.water);
    cateringEmissions = cateringEmissions + (event.emissions.coffee * emissionFactors.coffee);
    cateringEmissions = cateringEmissions + (event.emissions.tea * emissionFactors.tea);
    cateringEmissions = cateringEmissions + (event.emissions.wine * emissionFactors.wine);
    cateringEmissions = cateringEmissions + (event.emissions.beer * emissionFactors.beer);
    cateringEmissions = cateringEmissions + (event.emissions.spirits * emissionFactors.spirits);


    let promoMaterialsEmissions = 0;
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.marketingPaper * emissionFactors.marketingPaper);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.cardboard * emissionFactors.cardboard);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.billboards * emissionFactors.billboards);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.plastic * emissionFactors.plastic);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.giftPaper * emissionFactors.giftPaper);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.wood * emissionFactors.wood);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.tShirts * emissionFactors.tShirts);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.shirts * emissionFactors.shirts);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.jackets * emissionFactors.jackets);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.jumpers * emissionFactors.jumpers);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.trousers * emissionFactors.trousers);
    promoMaterialsEmissions = promoMaterialsEmissions + (event.emissions.shoes * emissionFactors.shoes);

    const totalEventEmissions = eventSpacesEmissions + cateringEmissions + promoMaterialsEmissions;
    

    // Calculate the attendee emissions
    const calculateTotalAttendeeEmissions = async (eventId) => {
         // Aggregated flight emissions
        let totalFlights = 0;
        const flightsQuery = query(collection(db, "flightEmissions"), where("event", "==", eventId));
        const flightsSnapshot = await getDocs(flightsQuery);
        flightsSnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        totalFlights = totalFlights + (doc.data().duration * emissionFactors.flightEmissions);
        });
        // Aggregated car emissions
        let totalCars = 0;
        const carsQuery = query(collection(db, "carEmissions"), where("event", "==", eventId));
        const carsSnapshot = await getDocs(carsQuery);
        carsSnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        totalCars = totalCars + (doc.data().distance * emissionFactors.carEmissions);
        });
        // Aggregated public transport emissions
        let totalPublicTransport = 0;
        const publicTransportQuery = query(collection(db, "publicTransportEmissions"), where("event", "==", eventId));
        const publicTransportSnapshot = await getDocs(publicTransportQuery);
        publicTransportSnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        totalPublicTransport = totalPublicTransport + (doc.data().duration * emissionFactors.publicTransportEmissions);
        });
        // Aggregated hotel stay emissions
        let totalHotelStay = 0;
        const hotelStayQuery = query(collection(db, "hotelStayEmissions"), where("event", "==", eventId));
        const hotelStaySnapshot = await getDocs(hotelStayQuery);
        hotelStaySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        totalHotelStay = totalHotelStay + (doc.data().duration * emissionFactors.hotelStayEmissions[doc.data().stars]);
        });
        return totalFlights + totalCars + totalPublicTransport + totalHotelStay;
    }
    
    const [totalAttendeeEmissions, setTotalAttendeeEmissions] = useState(0)

    useEffect(() => {
        const getAttendeeEmissions = async () => {
            const emissions = await calculateTotalAttendeeEmissions(eventId);
            setTotalAttendeeEmissions(emissions);
        }
        getAttendeeEmissions();
    })


    // Calculate the personal emissions
    const personalFlightEmissions = (flightEmissions ? (flightEmissions.find(flightEmission => flightEmission.event === eventId) ? flightEmissions.find(flightEmission => flightEmission.event === eventId).duration * emissionFactors.flightEmissions : 0) : 0);

    const personalCarEmissions = (carEmissions ? (carEmissions.find(carEmission => carEmission.event === eventId) ? carEmissions.find(carEmission => carEmission.event === eventId).distance * emissionFactors.carEmissions : 0) : 0);

    const personalPublicTransportEmissions = (publicTransportEmissions ? (publicTransportEmissions.find(publicTransportEmission => publicTransportEmission.event === eventId) ? publicTransportEmissions.find(publicTransportEmission => publicTransportEmission.event === eventId).duration * emissionFactors.publicTransportEmissions : 0) : 0);

    const hotelStay = hotelStayEmissions ? (hotelStayEmissions.find(hotelStayEmission => hotelStayEmission.event === eventId) ? hotelStayEmissions.find(hotelStayEmission => hotelStayEmission.event === eventId) : null) : null;
    const personalHotelEmissions = hotelStay ?  (hotelStay.duration * emissionFactors.hotelStayEmissions[hotelStay.stars]) : 0;

    const totalPersonalEmissions = personalFlightEmissions + personalCarEmissions + personalPublicTransportEmissions + personalHotelEmissions;

    return (
        <section className="page">


            <Header />


            <section className="page-content">

                <button className="back-button white" onClick={e => navigate(-1)}>&larr; Back</button>

                
                <h2 className="white">{event.name.toString()}</h2>
                
                <section className="emissions-summary-container">
                <article className="emissions-summary">
                    <h5 className="white">Event emissions:</h5>
                    <h4 className="orange">{totalEventEmissions.toFixed(2) + ' TCO2'}</h4>
                </article>
                <article className="emissions-summary">
                    <h5 className="white">Attendee emissions:</h5>
                    <h4 className="orange">{totalAttendeeEmissions.toFixed(2) + ' TCO2'}</h4>
                </article>
                </section>
                
                <h3 className="white">Event emissions</h3>
                
                <section className="card-list">
                    <Link to="event-spaces">
                        <article className="admin-emission-card">
                            <Location />
                            <h4 className="black">Event spaces</h4>
                            <p className="black">{eventSpacesEmissions ? eventSpacesEmissions.toFixed(2) + ' TCO2' : 'Not recorded'}</p>
                            {eventSpacesEmissions ? <button className="orange-border white-background orange">Edit</button> : <button className="orange-background white">Record Emissions</button>}
                        </article>
                    </Link>

                    <Link to="catering">
                        <article className="admin-emission-card">
                            <Catering />
                            <h4 className="black">Catering</h4>
                            <p className="black">{cateringEmissions ? cateringEmissions.toFixed(2) + ' TCO2' : 'Not recorded'}</p>
                            {cateringEmissions ? <button className="orange-border white-background orange">Edit</button> : <button className="orange-background white">Record Emissions</button>}
                        </article>
                    </Link>

                    <Link to="materials">
                        <article className="admin-emission-card">
                            <Materials />
                            <h4 className="black">Promo materials</h4>
                            <p className="black">{promoMaterialsEmissions ? promoMaterialsEmissions.toFixed(2) + ' TCO2' : 'Not recorded'}</p>
                            {promoMaterialsEmissions ? <button className="orange-border white-background orange">Edit</button> : <button className="orange-background white">Record Emissions</button>}
                        </article>
                    </Link>
                </section>

                <h3 className="white">My personal emissions</h3>
                <section className="card-list">
                    <Link to={`/${eventId}/personal-emissions`}>
                        <article className="admin-emission-card">
                            <Globe />
                            <h4 className="black">Personal emissions</h4>
                            <p className="black">{totalPersonalEmissions ? totalPersonalEmissions.toFixed(2) + ' TCO2' : 'Not recorded'}</p>
                            {totalPersonalEmissions ? <button className="orange-border white-background orange">Edit</button> : <button className="orange-background white">Record Emissions</button>}
                        </article>
                    </Link>
                </section>
            
            </section>


            <Footer />


        </section>
    )
}