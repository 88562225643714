// This component allows for the input of material emissions

// Import React
import React, { useState } from "react"

// Import react-router-dom stuff
import { useParams, useNavigate } from "react-router-dom";

// Import firestore stuff
import { doc, updateDoc } from "firebase/firestore";

// Import emission factors
import { emissionFactors } from "../app/emission_factors/emissionFactors";

// Import children components
import Header from "./Header"
import Footer from "./Footer"

// Import illustrations
import { ReactComponent as Marketing } from '../images/marketing.svg';
import { ReactComponent as Gift } from '../images/gift.svg';
import { ReactComponent as Clothes } from '../images/clothes.svg';


export default function MaterialsEmissions ({user, organisation, events, flightEmissions, carEmissions, publicTransportEmissions, hotelStayEmissions, db}) {
    const navigate = useNavigate();

    const { eventId } = useParams();

    const eventRef = doc(db, "events", eventId);

    const event = events.find(event => event.id === eventId);

    // For each input we need a state
    // We seperate the states into sections based on their cards and each section has their own logic to update their docs
    // Marketing
    const [marketingPaper, setMarketingPaper] = useState(event.emissions.marketingPaper);
    const [cardboard, setCardboard] = useState(event.emissions.cardboard);
    const [billboards, setBillboards] = useState(event.emissions.billboards);

    const totalMarketing = (marketingPaper * emissionFactors.marketingPaper) + (cardboard * emissionFactors.cardboard) + (billboards * emissionFactors.billboards);
    
    const handleSaveMarketing = async (event) => {
        event.preventDefault();
        const marketingButton = document.getElementById('marketing-button');
        marketingButton.textContent = 'Saving';
        await updateDoc(eventRef, {
            'emissions.marketingPaper': marketingPaper,
            'emissions.cardboard': cardboard,
            'emissions.billboards': billboards
        });
        marketingButton.textContent = 'Saved';
    };

    // Gifts
    const [plastic, setPlastic] = useState(event.emissions.plastic);
    const [giftPaper, setGiftPaper] = useState(event.emissions.giftPaper);
    const [wood, setWood] = useState(event.emissions.wood);

    const totalGifts = (plastic * emissionFactors.plastic) + (giftPaper * emissionFactors.giftPaper) + (wood * emissionFactors.wood);
    
    const handleSaveGifts = async (event) => {
        event.preventDefault();
        const giftsButton = document.getElementById('gifts-button');
        giftsButton.textContent = 'Saving';
        await updateDoc(eventRef, {
            'emissions.plastic': plastic,
            'emissions.giftPaper': giftPaper,
            'emissions.wood': wood
        });
        giftsButton.textContent = 'Saved';
    };


    // Clothes
    const [tShirts, setTShirts] = useState(event.emissions.tShirts);
    const [shirts, setShirts] = useState(event.emissions.shirts);
    const [jackets, setJackets] = useState(event.emissions.jackets);
    const [jumpers, setJumpers] = useState(event.emissions.jumpers);
    const [trousers, setTrousers] = useState(event.emissions.trousers);
    const [shoes, setShoes] = useState(event.emissions.shoes);

    
    const totalClothes = (tShirts * emissionFactors.tShirts) + (shirts * emissionFactors.shirts) + (jackets * emissionFactors.jackets) + (jumpers * emissionFactors.jumpers) + (trousers * emissionFactors.trousers) + (shoes * emissionFactors.shoes);
    
    const handleSaveClothes = async (event) => {
        event.preventDefault();
        const clothesButton = document.getElementById('clothes-button');
        clothesButton.textContent = 'Saving';
        await updateDoc(eventRef, {
            'emissions.tShirts': tShirts,
            'emissions.shirts': shirts,
            'emissions.jackets': jackets,
            'emissions.jumpers': jumpers,
            'emissions.trousers': trousers,
            'emissions.shoes': shoes
        });
        clothesButton.textContent = 'Saved';
    };
    
    return (
        <section className="page">

            <Header />

            <section className="page-content">
                
                <button className="back-button white" onClick={e => navigate(-1)}>&larr; Back</button>

                <h2 className="white">Promotional Material Emissions</h2>

                <section className="card-list">

                    <form className="input-card">
                        <Marketing />
                        <h4 className="white">Marketing</h4>
                        <h5 className="white">Total: {totalMarketing.toFixed(2)} TCO2</h5>
                        <article className="input-article">
                            <label className="white" for="marketingPaper">Kg of marketing paper</label>
                            <input type={"number"} name="marketingPaper" id="marketingPaper" placeholder="Kg of marketing paper" value={marketingPaper} onChange={event => {setMarketingPaper(event.target.value); document.getElementById('marketing-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="cardboard">Kg of cardboard</label>
                            <input type={"number"} name="cardboard" id="cardboard" placeholder="Kg of cardboard" value={cardboard} onChange={event => {setCardboard(event.target.value); document.getElementById('marketing-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="billboards">Number of billboards</label>
                            <input type={"number"} name="billboards" id="billboards" placeholder="Number of billboards" value={billboards} onChange={event => {setBillboards(event.target.value); document.getElementById('marketing-button').textContent = 'Save';}}/>
                        </article>
                        <button type="submit" id="marketing-button" className="orange-background white" onClick={handleSaveMarketing}>Saved</button>
                    </form>

                    <form className="input-card">
                        <Gift />
                        <h4 className="white">Gifts</h4>
                        <h5 className="white">Total: {totalGifts.toFixed(2)} TCO2</h5>
                        <article className="input-article">
                            <label className="white" for="plastic">Kg of plastic</label>
                            <input type={"number"} name="plastic" id="plastic" placeholder="Kg of plastic" value={plastic} onChange={event => {setPlastic(event.target.value); document.getElementById('gifts-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="giftPaper">Kg of gift paper</label>
                            <input type={"number"} name="giftPaper" id="giftPaper" placeholder="Kg of gift paper" value={giftPaper} onChange={event => {setGiftPaper(event.target.value); document.getElementById('gifts-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="wood">Kg of wood</label>
                            <input type={"number"} name="wood" id="wood" placeholder="Kg of wood" value={wood} onChange={event => {setWood(event.target.value); document.getElementById('gifts-button').textContent = 'Save';}}/>
                        </article>
                        <button type="submit" id="gifts-button" className="orange-background white" onClick={handleSaveGifts}>Saved</button>
                    </form>

                    <form className="input-card">
                        <Clothes />
                        <h4 className="white">Clothes</h4>
                        <h5 className="white">Total: {totalClothes.toFixed(2)} TCO2</h5>
                        <article className="input-article">
                            <label className="white" for="tShirts">Number of t-shirts</label>
                            <input type={"number"} name="tShirts" id="tShirts" placeholder="Number of t-shirts" value={tShirts} onChange={event => {setTShirts(event.target.value); document.getElementById('clothes-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="shirts">Number of shirts</label>
                            <input type={"number"} name="shirts" id="shirts" placeholder="Number of shirts" value={shirts} onChange={event => {setShirts(event.target.value); document.getElementById('clothes-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="jackets">Number of jackets</label>
                            <input type={"number"} name="jackets" id="jackets" placeholder="Number of jackets" value={jackets} onChange={event => {setJackets(event.target.value); document.getElementById('clothes-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="jumpers">Number of jumpers</label>
                            <input type={"number"} name="jumpers" id="jumpers" placeholder="Number of jumpers" value={jumpers} onChange={event => {setJumpers(event.target.value); document.getElementById('clothes-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="trousers">Number of trousers</label>
                            <input type={"number"} name="trousers" id="trousers" placeholder="Number of trousers" value={trousers} onChange={event => {setTrousers(event.target.value); document.getElementById('clothes-button').textContent = 'Save';}}/>
                        </article>
                        <article className="input-article">
                            <label className="white" for="shoes">Number of shoes</label>
                            <input type={"number"} name="shoes" id="shoes" placeholder="Number of shoes" value={shoes} onChange={event => {setShoes(event.target.value); document.getElementById('clothes-button').textContent = 'Save';}}/>
                        </article>
                        <button type="submit" id="clothes-button" className="orange-background white" onClick={handleSaveClothes}>Saved</button>
                    </form>

                </section>


            </section>

            <Footer />


        </section>
    )
};