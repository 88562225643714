// This component is at the bottom of every page

// Import react
import React from "react";

export default function Footer (props) {
    return (
        <footer className="footer">
            <p>BlocCarbon 2022</p>
        </footer>
    )
}