// This component shows a list of all the events

// Import react
import React from "react";

// Import react-router-dom stuff
import { Link } from "react-router-dom";

// Import firebase stuff 
import { collection, query, where, getDocs } from "firebase/firestore";

// Import child components
import Header from "./Header";
import EventSummaryCard from "./EventSummaryCard";
import Footer from "./Footer";

// Import emission factors
import { emissionFactors } from "../app/emission_factors/emissionFactors";


export default function Dashboard ({user, organisation, events, flightEmissions, carEmissions, publicTransportEmissions, hotelStayEmissions, db}) {

    const isAdmin = organisation.admins.indexOf(user.id) !== -1 ? true : false;
    
    return (
        <section className="page">
            <Header />
            <section className="page-content green-background">
                <h2 className="white">Welcome, {user.firstName.toString()}</h2>
                <h3 className="white">My Events</h3>
                <section className="card-list">
                    {events.map((event) => {return (
                        <EventSummaryCard isAdmin={isAdmin} event={event} user={user} organisation={organisation} events={events} flightEmissions={flightEmissions} carEmissions={carEmissions} publicTransportEmissions={publicTransportEmissions} hotelStayEmissions={hotelStayEmissions} db={db} />
                    )})}
                </section>
            </section>
            <Footer />
        </section>
    )
};