// This component allows for input of event spaces emissions

// Import React
import React, { useState, useEffect } from "react";

// Import react-router-dom stuff
import { useParams, useNavigate } from "react-router-dom";

// Import firestore stuff
import { doc, updateDoc } from "firebase/firestore";

// Import emission factors
import { emissionFactors } from "../app/emission_factors/emissionFactors";

// Import children components
import Header from "./Header";
import Footer from "./Footer";


export default function EventSpacesEmissions ({user, organisation, events, flightEmissions, carEmissions, publicTransportEmissions, hotelStayEmissions, db}) {

    const navigate = useNavigate();

    const { eventId } = useParams();

    const event = events.find(event => event.id === eventId);

    const initialEventSpacesEmissions = [...event.emissions.eventSpacesEmissions]

    const [eventSpacesEmissions, setEventSpacesEmissions] = useState(initialEventSpacesEmissions);

    const handleChange = (event, eventSpaceEmission, type, value) => {
        const saveButton = document.getElementById(`${eventSpaceEmission.name}-save-button`);
        saveButton.textContent = 'Save'
        let updatedEventSpaceEmission = eventSpacesEmissions.find(item => item === eventSpaceEmission);
        updatedEventSpaceEmission[type] = value;
        let newArr = [...eventSpacesEmissions];
        const index = newArr.indexOf(eventSpaceEmission);
        newArr[index] = updatedEventSpaceEmission;
        setEventSpacesEmissions(newArr);
    }

    const calculateSpaceEmissionTotal = (eventSpaceEmission) => {
        let total = 0    
        if (eventSpaceEmission.heating) {
            total = total + (eventSpaceEmission.hours * eventSpaceEmission.size * emissionFactors.eventSpaceEmissions.heating)
        }
        if (eventSpaceEmission.aircon) {
            total = total + (eventSpaceEmission.hours * eventSpaceEmission.size * emissionFactors.eventSpaceEmissions.aircon)
        }
        return total
    }

    const handleSave = async (event, eventSpaceEmission) => {
        event.preventDefault();
        const saveButton = document.getElementById(`${eventSpaceEmission.name}-save-button`);
        saveButton.textContent = 'Saving';
        const eventnRef = doc(db, "events", eventId);
        await updateDoc(eventnRef, {
            "emissions.eventSpacesEmissions": eventSpacesEmissions
          });
        saveButton.textContent = 'Saved';
    }

    const addNewSpace = (event) => {
        event.preventDefault();
        let newSpace = {
            name: `Event space ${eventSpacesEmissions.length + 1}`,
            hours: 0,
            size: 0,
            heating: false,
            aircon: false
        }
        setEventSpacesEmissions([...eventSpacesEmissions, newSpace])
    }

    // Make the checkboxes check properly
    useEffect(() => {
        eventSpacesEmissions.forEach(eventSpaceEmission => {
            if (eventSpaceEmission.heating) {
                document.getElementById(`${eventSpaceEmission.name}-heating`).checked = true;
            }
            if (!eventSpaceEmission.heating) {
                document.getElementById(`${eventSpaceEmission.name}-heating`).checked = false;
            }
            if (eventSpaceEmission.aircon) {
                document.getElementById(`${eventSpaceEmission.name}-aircon`).checked = true;
            }
            if (!eventSpaceEmission.aircon) {
                document.getElementById(`${eventSpaceEmission.name}-aircon`).checked = false;
            }
        })
    }, [])

    const handleCheckboxChange = (event, eventSpaceEmission, type) => {        
        const saveButton = document.getElementById(`${eventSpaceEmission.name}-save-button`);
        saveButton.textContent = 'Save'
        if (eventSpaceEmission[type]) {
            let updatedEventSpaceEmission = eventSpacesEmissions.find(item => item === eventSpaceEmission);
            updatedEventSpaceEmission[type] = false;
            let newArr = [...eventSpacesEmissions];
            const index = newArr.indexOf(eventSpaceEmission);
            newArr[index] = updatedEventSpaceEmission;
            setEventSpacesEmissions(newArr);
            document.getElementById(`${eventSpaceEmission.name}-${type}`).checked = false;
        }
        else if (!eventSpaceEmission[type]) {
            let updatedEventSpaceEmission = eventSpacesEmissions.find(item => item === eventSpaceEmission);
            updatedEventSpaceEmission[type] = true;
            let newArr = [...eventSpacesEmissions];
            const index = newArr.indexOf(eventSpaceEmission);
            newArr[index] = updatedEventSpaceEmission;
            setEventSpacesEmissions(newArr);
            document.getElementById(`${eventSpaceEmission.name}-${type}`).checked = true;
        }
    }

    return (
        <section className="page">


            <Header />


            <section className="page-content">

                <button className="back-button white" onClick={e => navigate(-1)}>&larr; Back</button>

                <h2 className="white">Event Spaces Emissions</h2>
                <section className="card-list">
                  
                    {eventSpacesEmissions.map((eventSpaceEmission) => {
                        return <form className="input-card">
                            <input className="event-space-name white" type={"text"} value={eventSpaceEmission.name} onChange={event => handleChange(event, eventSpaceEmission, 'name', event.target.value)} placeholder="Type name"/>
                            <h5 className="white">Total: {calculateSpaceEmissionTotal(eventSpaceEmission).toFixed(2)} TCO2</h5>
                            <article className="input-article">
                                <label className="white" htmlFor="hours">Number of hours</label>
                                <input type={"number"} name="hours" id="hours" placeholder="Number of hours" value={eventSpaceEmission.hours} onChange={event => handleChange(event, eventSpaceEmission, 'hours', event.target.value)}/>
                            </article>
                            <article className="input-article">
                                <label className="white" htmlFor="size">Approximate size m&#178;</label>
                                <input type={"number"} name="size" id="size" placeholder="Approximate size" value={eventSpaceEmission.size} onChange={event => handleChange(event, eventSpaceEmission, 'size', event.target.value)}/>
                            </article>
                            <article className="checkbox-container-article">
                                <article className="checkbox-article">
                                    <label className="white" htmlFor={`${eventSpaceEmission.name}-heating`}>Heating</label>
                                    <input type={"checkbox"} name={`${eventSpaceEmission.name}-heating`} id={`${eventSpaceEmission.name}-heating`} value={eventSpaceEmission.heating} onChange={event => handleCheckboxChange(event, eventSpaceEmission, 'heating', eventSpaceEmission.heating ? false : true)}/>
                                </article>
                                <article className="checkbox-article">
                                    <label className="white" htmlFor={`${eventSpaceEmission.name}-aircon`}>Aircon</label>
                                    <input type={"checkbox"} name={`${eventSpaceEmission.name}-aircon`} id={`${eventSpaceEmission.name}-aircon`} value={eventSpaceEmission.aircon} onChange={event => handleCheckboxChange(event, eventSpaceEmission, 'aircon', eventSpaceEmission.aircon ? false : true)}/>
                                </article>
                            </article>
                            <button type="submit" id={`${eventSpaceEmission.name}-save-button`} className="orange-background white" onClick={event => handleSave(event, eventSpaceEmission)}>Saved</button>
                        </form>
                    })}

                    <button className="add-new-card" onClick={addNewSpace}>
                        <p className="plus-button white">+</p>
                        <p className="white">Add new space</p>
                    </button>


                </section>


            </section>


            <Footer />


        </section>
    )
};